<template>
  <div class="nstion flex row-between">
    <div class="leet flex">
      <div class="color ml15" :style="{backgroundColor: item.hub?item.hub:item.hue}"><img :src="item.subjectIcon?item.subjectIcon:item.icon" ></div>
      <div class="coures f14 pl20">{{item.subjectTitle?item.subjectTitle:item.sujectName}}</div>
      <div class="pl60 f12 c666">{{item.courseTitle?item.courseTitle:item.name}}</div>
    </div>
    <div class="rist flex pr55">
      <!-- <img src="../assets/img/img21.png" v-if='!item.hasCases'> -->
      <img src="../assets/img/img7.png" class="cup" @click="goHas('video')">

      <img src="../assets/img/img20.png" v-if='!item.hasCases'>
      <img src="../assets/img/img8.png" class="cup" v-else @click="goHas('cases')">

      <img src="../assets/img/img19.png" v-if='!item.hasReading'>
      <img src="../assets/img/img9.png" class="cup" v-else @click="goHas('reading')">
    </div>
    <modal ref="modal"></modal>
  </div>
</template>

<script>
  import modal from '../components/modal.vue';
  import { mapState } from 'vuex';
  export default{
    components:{
      modal
    },
    props: {
      item: {
        type: Object
      },
      type: {
        type: String
      }
    },
    data(){
      return{
        pathName: '',
        show: false
      }
    },
    created() {
      this.pathName = this.$route.name;
    },
    computed: {
      ...mapState({
        user: state => state.stateUser
      })
    },
    methods:{
      async goHas(type){
        if(!this.user) return this.$bus.$emit('isLogin');
        var item = this.item;
        var id = item.courseId ? item.courseId : item.id;
        var res = await this.$api.get("/api/project_course/checkCourseCanView", {id: id, checkCan: true});
        if(res.code == 0){
          if(type == 'video'){
            this.$router.push({name: "videoPpt", query: {id: id}});
          }else{
            
            if(item.courseId || this.type == 'index'){
              this.$router.push('/home/courser?type='+type+'&id='+id);
            }else if(item.id){
              this.$router.push('/stuff/detail?type='+type+'&id='+id);
            }
          }
        }else{
          this.$refs.modal.showHand();
          return;
        }
      }
    }
  }
</script>

<style lang="scss">
  .nstion{
    width: 98%;
    background-color: #ecf1ff;
    height: 54px;
    margin-bottom: 10px;
    border-radius: 10px;
    .rist{
      img{
        margin-left: 36px;
      }
    }
    .leet{
      height: 54px;
      .coures{
        color: #5b68fb;
        font-weight: bold;
        width: 160px;
        text-align: left;
      }
      .color{
        width: 36px;
        height: 36px;
        border-radius: 8px;
        position: relative;
        overflow: hidden;
        img{
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%,-50%);
          width: 100%;
        }
      }
    }
  }
</style>
