<template>
  <div class="stuff-list pt50 plr40">
    <my-header
      type="search"
      v-model="text"
      @search="$throttle(search, 1000)"
    ></my-header>
    <div class="hds flex row-between">
      <div class="back flex f20 cup" @click="$router.go(-1)">
        <img src="@/assets/img/back.png" />
        <span>后退</span>
      </div>

      <div class="searched f16 c333">
        共查询到<span>{{ length }}</span
        >个结果
      </div>
    </div>

    <div class="list" ref="container">
      <course-list
        v-for="(item, index) in list"
        :key="index"
        :item="item"
      ></course-list>
      <div class="noMsg" v-if="!list.length && noMoreShow">
        <div class="img"><img src="../../assets/img/courseMsg.jpg" /></div>
        <div class="text-center f24 c333 pt10">查询无结果</div>
      </div>
    </div>
  </div>
</template>

<script>
import myHeader from '../../components/head.vue'
import courseList from '../../components/course-list.vue'
import { Scroll } from '@/utils/loading'
export default {
  components: {
    myHeader,
    courseList
  },
  data() {
    return {
      text: '',
      list: [],
      page: 1,
      length: 0,
      noMoreShow: false,
      loading: false
    }
  },
  mounted() {
    var value = this.$route.params.value
    if (value) {
      this.text = value
      this.getData()
    } else {
      this.text = ''
      this.list = []
    }
    // 下拉加载
    this.$nextTick(() => {
      this.$scroll(this.$refs.container, () => {
        if (this.length > this.list.length) {
          !this.loading && this.getData()
        }
      })
      Scroll.listenTouchDirection()
    })
  },
  methods: {
    getData() {
      this.noMoreShow = false
      this.loading = true
      var params = {
        page: this.page,
        pagesize: 15,
        name: this.text
      }
      this.$api
        .get('/api/project_course/courseListByName', params)
        .then(res => {
          if (res.code == 0) {
            var data = res.data.list
            this.length = res.data.count
            this.list = [...this.list, ...data]
          }
          this.page = this.page + 1
          this.noMoreShow = true
          this.loading = false
        })
    },
    // 开始搜索
    search() {
      if (!this.text) return this.$message.warning('关键词不能为空')
      this.list = []
      this.page = 0
      this.getData()
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.name == 'stuff-detail' && to.params.value) {
        vm.text = to.params.value
        vm.list = []
        vm.page = 0
        vm.total = -1
        vm.getData()
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.stuff-list {
  height: 100%;
  .list {
    overflow: auto;
    height: calc(100% - 140px);
    position: relative;
    .item {
      width: 98%;
      background-color: #ecf1ff;
      height: 80px;
      margin-bottom: 10px;
      border-radius: 10px;
      .rist {
        img {
          margin-left: 36px;
        }
      }
      .leet {
        height: 80px;
        .coures {
          color: #5b68fb;
          font-weight: bold;
        }
        .color {
          width: 54px;
          height: 54px;
          border-radius: 8px;
          position: relative;
          img {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
  }
  .course-list {
    overflow: auto;
    position: relative;
    width: 100%;
    height: calc(100% - 170px);
  }
  .hds {
    padding-top: 20px;
    padding-bottom: 20px;
    .searched {
      font-weight: bold;
      span {
        color: red;
      }
    }
    .back {
      color: #6672fb;
      span {
        font-weight: bold;
      }
      img {
        width: 22px;
        height: 22px;
        vertical-align: middle;
        margin-right: 15px;
      }
    }
  }
}
</style>
